import {
    IonButton,
    IonIcon,
    IonItem,
    IonLabel,
    IonCard,
    IonCheckbox,
} from '@ionic/vue';

import { useRoute, useRouter } from "vue-router";
import {ref, computed, defineComponent , nextTick} from "vue";
import {
    createLoadSpinner, saveAudioToDevice, saveApp,
} from "@/services/ShareService";
import {
    getMediaItem,
    Journey,
    getJourney,
    updateJourney,
    getJourneySet, JourneyTab,
} from "@/storage/DataStorage";
import {chevronBack, help, shareSocial} from "ionicons/icons";
import { useStore } from "vuex";
import translate from "../translate";
import { openJourneyHelpPagesModal } from "@/services/InformationPagesService";
import AudioPlayer from '@/components/AudioPlayer.vue';
import { jsPDF } from "jspdf";
import {FileIdentifiers, MimeTypes} from "@/types/types";
import {ActionSheetBuilder} from "@/utilities/ActionSheetBuilder";
import {shareFile, writeFile} from "@/utilities/Filesystem";
import {correctFixedPositionOnRotate, EXTRA_PDF_FONT_NAME, getPDFFont} from "@/utilities/Styling";
import {getImageDataUri} from "@/utilities/Compatibility";
import {UNIVERSAL_LANGUAGE_CODE} from "@/utilities/JourneySet";
import {getFromS3} from "@/services/AWSService";
import JourneyBody from '@/components/JourneyBody.vue';
import {Swiper, SwiperSlide} from 'swiper/swiper-vue';

// import Swiper styles
import 'swiper/swiper-bundle.css';

import {Navigation, Pagination, Scrollbar, Zoom, Keyboard, Autoplay} from 'swiper/modules';
const X_MARGIN = 20;
const Y_MARGIN = 30;
const IMG_WIDTH = 90;
const IMG_HEIGHT = 90;
const clientWidth = window.document.body.clientWidth > 1024 ? 1024 : window.document.body.clientWidth;
const clientHeight = window.document.body.clientHeight;
const PIXEL_TO_PT_RATIO = 3 / 4; //pixels weren't cooperating
const PDF_WIDTH = clientWidth * PIXEL_TO_PT_RATIO;

export default defineComponent({
    name: "Journey",
    components: {
        Swiper,
        SwiperSlide,
        IonIcon,
        IonItem,
        IonLabel,
        IonCard,
        IonButton,
        IonCheckbox,
        AudioPlayer,
        JourneyBody
    },
    ionViewWillLeave() {
        this.$store.dispatch("setShouldPauseAudio", true);

        window.removeEventListener('resize', this.resizeEvent);
        window.removeEventListener('orientationchange', this.sizeTabs);
    },
    async ionViewWillEnter() {
        await this.initializeJourneyPage();
        await this.$store.dispatch("setHeaderTitle", "");

        this.tabLabels = window.document.getElementsByClassName('tab__text');
        this.sizeTabs();
        
        window.addEventListener('resize', this.resizeEvent);
        window.addEventListener('orientationchange', this.sizeTabs);
    },
    data() {
        return {
            swiperOptions: {
                loop: false,
                autoHeight: false,
                slidesPerView: 1,
                threshold: 10,
                initialSlide: 0
            },
            modules: [Autoplay, Keyboard, Zoom],
            paginationOptions: {
                el: 'tabs-container',
                clickable: true,
                bulletClass: 'tab'
            }
        }
    },
    setup: function () {
        const {t} = translate();
        const router = useRouter();
        const route = useRoute();
        const store = useStore();
        const journey = ref({} as Journey);
        const currentFieldId = computed(() => route.params.fieldId);
        const currentSetId = computed(() => route.params.journeySetId as string);
        const currentJourneyId = computed(() => route.params.journeyId as string);
        const headerImage = ref('');
        const contentImage = ref('');
        const journeyAudio = ref('' as string);
        const contentText = ref("" as string);
        const audioStartTime = ref(0);
        const audioEndTime = ref(0);
        const pdfContentImage = ref('');
        const pdfContentText = ref("" as string);
        const activeTab = ref(0);
        const shouldShowJourneyPage = ref(false);
        const journeyContentImages = ref([] as string[]);
        const journeyTabImages = ref([] as string[]);
        const journeySlides = ref();
        const swiperRef = ref();
        const journeyFooter = ref();
        const tabLabels = ref();
        const localeMessages = computed(() => store.getters.getLocaleMessages());
        const journeySetLocale = ref('');
        const usePdfExtraFont = ref(false);
        const FLEX_SAME_TAB_WIDTH = '1 1 0';
        const FLEX_AUTO_TAB_WIDTH = '1 1 auto';
        let slideXDifference = 0;
        const videoIdentifier = '@@Video@@';
        const videoLabelIdentifier = '@@VideoLabelEnd@@';
        const dir = computed((): string => t("messages.language.textDirection"));

        const setSwiperInstance = (swiper: any) => {
            journeySlides.value = swiper;
        }
        
        function resizeEvent() {
            correctFixedPositionOnRotate(journeyFooter.value);
            if (store.getters.isPwa()) {
                sizeTabs();
            }
        }
        
        function sizeTabs() {
            let textTruncated = false;
            for (const label of tabLabels.value) {
                textTruncated = (label as HTMLElement).offsetWidth < (label as HTMLElement).scrollWidth;
                if (textTruncated) {
                    break;
                }
            }
            window.document.body.style.setProperty('--tab-flex', textTruncated ? FLEX_SAME_TAB_WIDTH : FLEX_AUTO_TAB_WIDTH);
        }

        function svgString2Image(svgString: string, width: number, format: string): Promise<any> {
            return new Promise((resolve) => {
                format = format ? format : 'png';
                const svgData = 'data:image/svg+xml;base64,' + svgString;
                const canvas = window.document.createElement('canvas');
                const context = canvas.getContext('2d');
                canvas.width = width;
                canvas.height = canvas.width;
                const image = new Image();
                image.onload = function () {
                    const ratioHeight = width * image.height / image.width;
                    context?.clearRect(0, 0, width, ratioHeight);
                    context?.drawImage(image, 0, width - ratioHeight, width, ratioHeight);
                    resolve(pdfContentImage.value = canvas.toDataURL('image/' + format));
                };
                image.onerror = function() {
                    resolve(pdfContentImage.value = '');
                };
                image.src = svgData;
            });
        }

        async function getImageFromId(imageId: string) {
            const imageObject = await getMediaItem(imageId);
            if (imageObject && imageObject.content) {
                return getImageDataUri(imageObject.content);
            }
            return '';
        }

        async function getAudioFromId(audioId: string | undefined) {
            if (!audioId) {
                return ''
            }
            const mediaObject = await getMediaItem(audioId);
            return (mediaObject && mediaObject.content) ? mediaObject.content : 'empty';
        }

        async function setToComplete() {
            // need to fetch actual DB item we want to update since vue uses proxies...
            const journeyToUpdate = await getJourney(journey.value.id);
            journeyToUpdate.isComplete = true;
            await updateJourney(journeyToUpdate);
        }

        async function goToTab(goToTab: number) {
           await journeySlides.value.slideTo(goToTab,100,() => {return;});
        }
        async function activateTab() {
            if(journeySlides.value) {
                try {
                    await nextTick(() => {
                        journeySlides.value.slidesEl.getElementsByClassName("swiper-slide")
                            .item(journeySlides.value.activeIndex)
                            .scrollIntoView({block:"start" , behavior:"smooth"})
                    });
                }catch(e){
                    console.error(e);
                }
            }
            journeySlides.value.update();
            audioStartTime.value = Number(journey.value.tabs[activeTab.value].audioStartTime) || 0;
            audioEndTime.value = Number(journey.value.tabs[activeTab.value].audioEndTime) || 0;            
        }

        async function changeSlide() {
            if(activeTab.value === journeySlides.value.activeIndex) {
                return;
            }
            activeTab.value = journeySlides.value.activeIndex;
            await activateTab();
            if (activeTab.value === journey.value.tabs.length - 1) {
                await setToComplete();
            }
        }
        
        function audioLoaded() {
            //TODO in near future
        }
 
        const onSlideTouchStart = (swiper: any, event: CustomEvent) => {
            slideXDifference = event.detail.changedTouches ? event.detail.changedTouches[0].pageX : 0;
        }

        const onSlideTouchEnd = (swiper: any, event: CustomEvent) => {
            slideXDifference -= event.detail.changedTouches ? event.detail.changedTouches[0].pageX : 0;
            const BUFFER_FOR_VERTICAL_SCROLL = -150;
            if ((t("messages.language.textDirection") === "ltr" && activeTab.value === 0 && slideXDifference < BUFFER_FOR_VERTICAL_SCROLL) ||
                (t("messages.language.textDirection") === "rtl" && activeTab.value === 0 && slideXDifference > Math.abs(BUFFER_FOR_VERTICAL_SCROLL))) {
                router.push({
                    name: 'journeys',
                    params: {fieldId: currentFieldId.value, journeySetId: currentSetId.value}
                });
            }
        }
        
        //vue3-markdown-it includes any text after a list as part of the last list item without this
        function setupTabs(tabs: JourneyTab[]) {
            tabs.forEach((tab: JourneyTab, index: number) => {
                /* Regex looks for this format:
                *  * [stuff]&nbsp; 
                *  [other stuff] OR *[other stuff]* OR **[other stuff]** OR ***[other stuff]***
                * 
                * *[other stuff]* = italic
                * **[other stuff]** = bold
                * ***[other stuff]*** = bold italic
                */
                if (tab.contentText) {
                    const regex = /(\* .*?&nbsp;\s*\n+[^*\w[])(\**[\w[]+.*)/gm;
                    let match = regex.exec(tab.contentText);
                    while (match != null) {
                        tab.contentText = tab.contentText.replace(match[1], match[1] + '\n\n');
                        match = regex.exec(tab.contentText);
                    }

                    const videoState = { workingText: tab.contentText };
                    tab.text1 = parseNextVideoSection(videoIdentifier, videoState);
                    tab.videoLabel = parseNextVideoSection(videoLabelIdentifier, videoState);
                    tab.videoUrl = parseNextVideoSection(videoIdentifier, videoState);
                    tab.text2 = videoState.workingText.replace(videoIdentifier, '');
                }
                
                tabs.splice(index,1,tab);
            });
        }

        function parseNextVideoSection(identifier: string,  videoState: { workingText: string }): string {
            const index = videoState.workingText.indexOf(identifier);
            if(index >= 0) {
                const toReturn = videoState.workingText.substring(0, index);
                videoState.workingText = videoState.workingText.substring(index + identifier.length)
                return toReturn;
            }

            return '';
        }
        
        //force links generated by vue3-markdown-it to open in a new tab
        function hrefOpenInNewTab() {
            const anchors = document.querySelectorAll('a');
            for (let i = 0; i < anchors.length; i++) {
                anchors[i].setAttribute('target', '_blank');
            }
        }

        const initializeJourneyPage = async () => {
            shouldShowJourneyPage.value = false;
            const loading = await createLoadSpinner();
            await loading.present();
            const journeySet = await getJourneySet(currentSetId.value);
            const tmpJourney = await getJourney(currentJourneyId.value);
            if (!journeySet || !tmpJourney) {
                await loading.dismiss();
                await router.push({ name: `fields` });
                return;
            }
            
            journeySetLocale.value = journeySet.locale ?? '';
            journey.value = tmpJourney
            journeyContentImages.value = journeySet.tabContentImages.map((image) => {
                return getImageDataUri(image);
            })
            journeyTabImages.value = journeySet.tabImages.map((image) => {
                return getImageDataUri(image);
            });
            headerImage.value = await getImageFromId(journeySet.iconId);
            store.dispatch("setHeaderImage", headerImage);
            contentImage.value = headerImage.value;
            await svgString2Image(contentImage.value.split(",")[1], 3000, 'png');
            store.dispatch("addRecentJourney", currentJourneyId.value);
            setupTabs(journey.value.tabs);
            journeyAudio.value = await getAudioFromId(journey.value.audioFileId);
            await goToTab(0);
            activateTab();
            hrefOpenInNewTab();
            await loading.dismiss();
            shouldShowJourneyPage.value = true;
        }

        const getSplitText = (input: string, doc: jsPDF) => {
            return input ? doc.splitTextToSize(doc.processArabic(input.replace(/&nbsp;/g, '').trim()), PDF_WIDTH - (2 * X_MARGIN)) : '';
        }
        
        function addFontFile(doc: jsPDF, font: string, fontStyle: string) {
            doc.addFileToVFS(`${ fontStyle }-font.ttf`, font);
            doc.addFont(`${ fontStyle }-font.ttf`, EXTRA_PDF_FONT_NAME, fontStyle);
        }
        
        async function handleWebExtraPdfFonts(doc: jsPDF) {
            const rootLanguageFolder = window.location.hostname.split(".")[0];
            const rootFolder = rootLanguageFolder === 'localhost' ? 'discoverystudies' : rootLanguageFolder;
            try {
                let result = await getFromS3(rootFolder, 'font/italic-font.txt', 'text',true);
                const normalFont = result.data;
                result = await getFromS3(rootFolder, 'font/normal-font.txt', 'text', true);
                const italicFont = result.data;

                if (normalFont && italicFont) {
                    addFontFile(doc, normalFont, 'normal');
                    addFontFile(doc, italicFont, 'italic');

                    usePdfExtraFont.value = true;
                }
            } catch {
                // If an instance doesn't have the font folders set up in S3, use the default font
            }
        }
        
        async function handleMobileExtraPdfFonts(doc: jsPDF) {
            try{
                let file = await fetch('./assets/font/normal-font.txt');
                const normalFont = await file.text();
                file = await fetch('./assets/font/italic-font.txt');
                const italicFont = await file.text();

                if (normalFont.trim() && !normalFont.startsWith('<!DOCTYPE') && italicFont.trim() && !italicFont.startsWith('<!DOCTYPE')) {
                    addFontFile(doc, normalFont, 'normal');
                    addFontFile(doc, italicFont, 'italic');

                    usePdfExtraFont.value = true;
                } 
            } catch {
                // If an instance doesn't have the font folders set up in S3, use the default font
            }
        }

        /**
         * Retrieve fonts to use in pdf print. See jsPDF documentation for more details and the latest recommended font
         * converter for their package
         * https://www.npmjs.com/package/jspdf
         * https://github.com/parallax/jsPDF
         */
        const includeExtraPdfFonts = async (doc: jsPDF) => {
            if (journeySetLocale.value === UNIVERSAL_LANGUAGE_CODE) {
                usePdfExtraFont.value = false;
            } else if (store.getters.isPwa()) {
                await handleWebExtraPdfFonts(doc);
            } else {
                await handleMobileExtraPdfFonts(doc);
            }
        }
        
        const cleanVideosForPdf = (text: string): string => {
            const videoStartIndex = text.indexOf(videoIdentifier);
            if(videoStartIndex > -1) {
                const videoEndIndex = text.indexOf(videoIdentifier,videoIdentifier.length) + videoIdentifier.length;
                return text.substring(videoEndIndex);
            }
            return text;
        }
        
        const handleTextContent = (doc: any, segment: string, currentY: number, isRtl: boolean): number => {            
            let currentFontSize;
            let splitText = '';
            segment = cleanVideosForPdf(segment);
            if (segment.trim().search("##") > -1) {
                currentY += 20;
                currentFontSize = 15;
                doc.setFontSize(currentFontSize);
                doc.setTextColor(getComputedStyle(window.document.body).getPropertyValue('--color-primary').trim());
                splitText = getSplitText(segment.replace(/#/g, ''), doc);
            } else {
                currentFontSize = 12;
                doc.setFontSize(currentFontSize);
                doc.setTextColor("#000000");
                splitText = getSplitText(segment.replace(/\*/g, ''), doc);
            }
            for (const text of splitText) {
                if (text) {
                    if (currentY >= clientHeight - Y_MARGIN) {
                        doc.addPage();
                        currentY = Y_MARGIN;
                    }
                    doc.text(text, isRtl ? PDF_WIDTH - X_MARGIN : X_MARGIN, currentY, {
                        align: isRtl ? "right" : "left",
                        isOutputRtl: isRtl
                    });
                    currentY += currentFontSize * doc.getLineHeightFactor();
                }
            }
            currentY += 10;
            return currentY;
        }
        
        const imageIndexOf = (segment: string, includeMetaData: boolean): number => {
            const imgBase64Identifier = includeMetaData ? /!\[.*\]\(data:image/gm : /data:image/gm;
            return segment.search(imgBase64Identifier);
        }
        
        const handleSegmentImages = (doc: any, currentY: number, segment: string, rtl: boolean): number => {
            const imageIndex = imageIndexOf(segment, false);
            if (imageIndex > -1) {
                const endImageIndex = segment.indexOf(')',imageIndex);
                try {                    
                    const img = segment.substring(imageIndex,endImageIndex);
                    doc.addImage(img, 'PNG', X_MARGIN, currentY, IMG_WIDTH, IMG_HEIGHT, '', 'SLOW');
                    currentY += IMG_HEIGHT + 10;
                } catch (e) {
                    console.error('Bad image');
                    console.error(e);
                }
                if(segment.length > endImageIndex + 1) {
                    currentY = handleTextContent(doc, segment.substring(endImageIndex + 1), currentY, rtl)
                } 
            }
            return currentY;
        }
        
        const handleSegment = (doc: any, segment: string, currentY: number, rtl: boolean): number => {
            const imageIndex = imageIndexOf(segment, true);
            
            if(imageIndex > -1) {
                if(imageIndex > 0) {
                    currentY = handleTextContent(doc, segment.substring(0,imageIndex), currentY, rtl);
                    currentY = handleSegmentImages(doc,currentY,segment.substring(imageIndex), rtl);
                } else {
                    currentY = handleSegmentImages(doc,currentY,segment,rtl);
                }
            } else {
                currentY = handleTextContent(doc, segment, currentY, rtl);
            }
            
            return currentY;
        } 

        const generatePdf = async () => {
            const loading = await createLoadSpinner();
            await loading.present();
            const isRtl = journeySetLocale.value !== UNIVERSAL_LANGUAGE_CODE && t("messages.language.textDirection") === "rtl";
            const doc = new jsPDF({
                orientation: clientHeight > PDF_WIDTH ? 'p' : 'l',
                unit: 'pt',
                format: [PDF_WIDTH, clientHeight]
            });
            
            await includeExtraPdfFonts(doc);
            const font = getPDFFont(usePdfExtraFont.value);
            let currentY = 0;
            let currentFontSize = 18;

            if (pdfContentImage.value) {
                doc.addImage(pdfContentImage.value, 'PNG', (PDF_WIDTH / 2) - (IMG_WIDTH / 2), Y_MARGIN, IMG_WIDTH, IMG_HEIGHT, '', 'SLOW');
                currentY = Y_MARGIN + IMG_HEIGHT + Y_MARGIN;
            }

            doc.setFontSize(currentFontSize);
            doc.setFont(font, 'normal');
            let splitText = getSplitText(journey.value.name, doc);
            doc.text(splitText, isRtl ? PDF_WIDTH - X_MARGIN : (PDF_WIDTH / 2), currentY, {
                align: isRtl ? "right" : "center",
                isOutputRtl: isRtl
            });
            currentY += (splitText.length * currentFontSize * doc.getLineHeightFactor()) + 10;

            currentFontSize = 16;
            doc.setFontSize(currentFontSize);
            doc.setTextColor("#444444");
            doc.setFont(font, 'italic');
            splitText = getSplitText(journey.value.description, doc);
            doc.text(splitText, isRtl ? PDF_WIDTH - X_MARGIN : (PDF_WIDTH / 2), currentY, {
                align: isRtl ? "right" : "center",
                isOutputRtl: isRtl
            });
            currentY += (splitText.length * currentFontSize * doc.getLineHeightFactor());

            doc.setFont(font, 'normal');
            for (const tab of journey.value.tabs) {
                for (const segment of tab.contentText.split("\n").filter((content: any) => content.trim() !== "")) {
                    currentY = handleSegment(doc,segment,currentY,isRtl);
                }
            }

            const journeySet = await getJourneySet(currentSetId.value as string);
            const pdfFileName = `${journeySet.pdfName}_${journey.value.order}_${ FileIdentifiers.Pdf }.pdf`;
            doc.setDisplayMode("fullwidth");
            const base64Pdf = doc.output('datauristring', {
                filename: pdfFileName
            });
            await loading.dismiss();
            return { pdfFileName: pdfFileName, base64Pdf: base64Pdf };
        }

        const sharePdf = async () => {
            const { pdfFileName, base64Pdf } = await generatePdf();
            await shareFile(pdfFileName, base64Pdf.split(",")[1], false, 'application/pdf');
        }

        const savePdf = async () => {
            const { pdfFileName, base64Pdf } = await generatePdf();
            await writeFile(pdfFileName, base64Pdf.split(",")[1], false, MimeTypes.PDF, false);
        }

        const shareContent = async (audioFileId: string) => {
            try {
                const actionSheetBuilder = new ActionSheetBuilder(journey.value.name);
                if (t("messages.buildConfigs.config.shareUnencryptedFunctionality") === "true") {
                    if (store.getters.isPwa()) {
                        actionSheetBuilder
                            .addSavePdf(savePdf)
                            .addSaveAudio(async () => {
                                const journeySet = await getJourneySet(currentSetId.value as string);
                                await saveAudioToDevice(audioFileId, `${journeySet.pdfName}_${journey.value.order}`);
                            });
                    } else {
                        actionSheetBuilder.addSharePdf(journey.value.name, sharePdf, savePdf).addShareAudio(journey.value, currentSetId.value, audioFileId);
                    }
                }

                if (store.getters.isPwa()) {
                    actionSheetBuilder.addSaveToDevice(async () => await saveApp())
                } else if (store.getters.platform() === "ios" || localeMessages.value.buildConfigs.config.appStoreBuild === 'true') {
                    actionSheetBuilder.addAppOptions(true);
                } else if (localeMessages.value.buildConfigs.config.appStoreBuild === 'false') {
                    actionSheetBuilder.addAppOptions(false);
                }
                
                actionSheetBuilder.addClose();
                const actionSheet = await actionSheetBuilder.create();
               await actionSheet.present();
            } catch {
                return;
            }
        };

        return {
            setSwiperInstance,
            localeMessages,
            router,
            journey,
            headerImage,
            contentImage,
            contentText,
            pdfContentImage,
            pdfContentText,
            shareContent,
            chevronBack,
            help,
            shareSocial,
            getImageFromId,
            t,
            currentJourneyId,
            returnRoute: computed(() => `/fields/${currentFieldId.value}/journey-sets/${currentSetId.value}/journeys`),
            openJourneyHelpPagesModal,
            initializeJourneyPage,
            setToComplete,
            activeTab,
            journeySlides,
            goToTab,
            onSlideTouchStart,
            onSlideTouchEnd,
            journeyAudio,
            audioStartTime,
            audioEndTime,
            changeSlide,
            shouldShowJourneyPage,
            journeyContentImages,
            journeyTabImages,
            journeyFooter,
            audioLoaded,
            tabLabels,
            sizeTabs,
            resizeEvent,
            dir
        };
    },
});
