import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createBlock as _createBlock, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2412c58c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "page-container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  class: "footer",
  ref: "journeyFooter"
}
const _hoisted_4 = { class: "tabs-container" }
const _hoisted_5 = { class: "tabs" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = { class: "tab__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_journey_body = _resolveComponent("journey-body")!
  const _component_swiper_slide = _resolveComponent("swiper-slide")!
  const _component_swiper = _resolveComponent("swiper")!
  const _component_audio_player = _resolveComponent("audio-player")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_base_layout = _resolveComponent("base-layout")!

  return (_openBlock(), _createBlock(_component_base_layout, {
    returnRoute: _ctx.returnRoute,
    shouldShowShareIcon: true,
    shouldShowHelpIcon: true,
    onShare: _cache[0] || (_cache[0] = ($event: any) => (_ctx.shareContent(_ctx.journey.audioFileId))),
    onHelp: _cache[1] || (_cache[1] = ($event: any) => (_ctx.openJourneyHelpPagesModal(_ctx.localeMessages)))
  }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, [
        _createVNode(_component_swiper, {
          dir: _ctx.dir,
          options: _ctx.swiperOptions,
          modules: _ctx.modules,
          onSlideChangeTransitionStart: _ctx.changeSlide,
          onTouchStart: _ctx.onSlideTouchStart,
          onTouchEnd: _ctx.onSlideTouchEnd,
          onSwiper: _ctx.setSwiperInstance,
          ref: "swiperRef"
        }, {
          default: _withCtx(() => [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.journey.tabs, (tab, index) => {
              return (_openBlock(), _createBlock(_component_swiper_slide, {
                key: index,
                class: "journey-content",
                style: {"scroll-margin-block-start":"160px"}
              }, {
                default: _withCtx(() => [
                  _createElementVNode("h1", null, _toDisplayString(_ctx.journey.name), 1),
                  _createElementVNode("img", {
                    src: _ctx.journeyContentImages[index],
                    class: "journey-header-img",
                    alt: "journey-header"
                  }, null, 8, _hoisted_2),
                  _createVNode(_component_journey_body, { tab: tab }, null, 8, ["tab"])
                ]),
                _: 2
              }, 1024))
            }), 128))
          ]),
          _: 1
        }, 8, ["dir", "options", "modules", "onSlideChangeTransitionStart", "onTouchStart", "onTouchEnd", "onSwiper"]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_audio_player, {
            onAudioLoaded: _ctx.audioLoaded,
            "audio-data": _ctx.journeyAudio,
            "start-time-seconds": _ctx.audioStartTime,
            "end-time-seconds": _ctx.audioEndTime,
            color: "--color-primary"
          }, null, 8, ["onAudioLoaded", "audio-data", "start-time-seconds", "end-time-seconds"]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.journey.tabs?.slice(), (tab, index) => {
                return (_openBlock(), _createElementBlock("span", {
                  key: index,
                  class: _normalizeClass(["tab", {active: _ctx.activeTab === index}]),
                  onClick: ($event: any) => (_ctx.goToTab(index))
                }, [
                  _createElementVNode("div", null, [
                    _createVNode(_component_ion_icon, {
                      class: "tab__icon",
                      src: _ctx.journeyTabImages[index],
                      alt: ""
                    }, null, 8, ["src"])
                  ]),
                  _createElementVNode("span", _hoisted_7, _toDisplayString(tab.tabName), 1)
                ], 10, _hoisted_6))
              }), 128))
            ])
          ])
        ], 512)
      ], 512), [
        [_vShow, _ctx.shouldShowJourneyPage]
      ])
    ]),
    _: 1
  }, 8, ["returnRoute"]))
}