import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_vue3_markdown_it = _resolveComponent("vue3-markdown-it")!
  const _component_video_player = _resolveComponent("video-player")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.tab.text1)
      ? (_openBlock(), _createBlock(_component_vue3_markdown_it, {
          key: 0,
          source: _ctx.tab.text1,
          breaks: ""
        }, null, 8, ["source"]))
      : _createCommentVNode("", true),
    (_ctx.tab.videoUrl && !_ctx.isSecure)
      ? (_openBlock(), _createBlock(_component_video_player, {
          key: 1,
          "video-label": _ctx.tab.videoLabel,
          "video-url": _ctx.tab.videoUrl
        }, null, 8, ["video-label", "video-url"]))
      : _createCommentVNode("", true),
    (_ctx.tab.text2)
      ? (_openBlock(), _createBlock(_component_vue3_markdown_it, {
          key: 2,
          source: _ctx.tab.text2,
          breaks: ""
        }, null, 8, ["source"]))
      : _createCommentVNode("", true)
  ], 64))
}