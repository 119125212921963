
import {computed, defineComponent} from 'vue';
import VideoPlayer from '@/components/VideoPlayer.vue';
import {store} from '@/store';
import {JourneyTab} from '@/storage/DataStorage';

export class Tab implements JourneyTab {
  tabName = '';
  contentText= '';
  isActive?: boolean = undefined;
  audioStartTime?: number = undefined;
  audioEndTime?: number = undefined;
  text1 = '';
  text2 = '';
  videoUrl?: string = undefined;
  videoLabel?: string = undefined;
}

export default defineComponent({
  name: "JourneyBody",
  components: {
    VideoPlayer
  },
  props: ["tab"],
  setup: function(props) {
    const isSecure = computed(() => store.getters.isSecure());
    
    return {
      isSecure
    }
  }
});
