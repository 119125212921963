
import {defineComponent, onMounted, ref} from 'vue';
import {videoParse} from '@/utilities/JourneySet';
import {IonLabel} from '@ionic/vue';

export default defineComponent({
  name: "VideoPlayer",
  props: {
    videoLabel: {
      type: String
    },
    videoUrl: {
      type: String,
      required: true
    }
  },
  components: {
    IonLabel
  },
  setup: function (props) {
    const youtubeLink = ref('');
    const vimeoLink = ref('');
    const otherLink = ref('');
    const youtubeIdentifier1 = 'https://www.youtube.com';
    const youtubeIdentifier2 = 'https://youtu.be/';
    const youtubeOptions = ['https://youtu.be/', 'https://www.youtube.com/embed/', 'https://www.youtube.com/watch?v='];
    const vimeoIdentifier = 'https://vimeo.com/';
    
    onMounted( () => {
      try {
        youtubeLink.value = parseYoutube();
        vimeoLink.value = videoParse(props.videoUrl,vimeoIdentifier, true);
        if(!youtubeLink.value && !vimeoLink.value) {
          otherLink.value = props.videoUrl;
        }
      } catch (e) {
        console.error(e);
      }      
    })
    
    function parseYoutube(): string {
      if(props.videoUrl.indexOf(youtubeIdentifier1) >= 0 || props.videoUrl.indexOf(youtubeIdentifier2) >= 0) {
        for(const option of youtubeOptions) {
          const toReturn = videoParse(props.videoUrl,option, true);
          if(toReturn) {
            return toReturn;
          }
        }
      }
      
      return '';
    }
    
    return {
      youtubeLink,
      vimeoLink,
      otherLink
    }
  }
});
