import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3618dac2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "video-container" }
const _hoisted_2 = ["src"]
const _hoisted_3 = ["src"]
const _hoisted_4 = {
  key: 2,
  preload: "auto",
  controls: "",
  class: "video"
}
const _hoisted_5 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.videoLabel)
      ? (_openBlock(), _createBlock(_component_ion_label, {
          key: 0,
          class: "video-label"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.videoLabel), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_1, [
      (_ctx.youtubeLink)
        ? (_openBlock(), _createElementBlock("iframe", {
            key: 0,
            class: "video",
            src: `https://www.youtube.com/embed/${_ctx.youtubeLink}`,
            title: "YouTube video player",
            frameborder: "0",
            allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
            allowfullscreen: ""
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true),
      (_ctx.vimeoLink)
        ? (_openBlock(), _createElementBlock("iframe", {
            key: 1,
            src: `https://player.vimeo.com/video/${_ctx.vimeoLink}`,
            class: "video",
            frameborder: "0",
            allow: "autoplay; fullscreen; picture-in-picture",
            allowfullscreen: ""
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true),
      (_ctx.otherLink)
        ? (_openBlock(), _createElementBlock("video", _hoisted_4, [
            _createElementVNode("source", { src: _ctx.otherLink }, null, 8, _hoisted_5)
          ]))
        : _createCommentVNode("", true)
    ])
  ], 64))
}